<template lang="pug">
v-card(:class='childCaseData.length >= 8 ? "dialog-height-small" : ""')
  v-col.d-flex.align-center.pa-5.pb-0
    v-row(no-gutters)
      v-col(cols="8")
        .d-flex.align-center.height-62
          h2.my-0(style="white-space:nowrap;") {{$t('CHILD_CASE.TITLE')}}
          v-btn.ml-3.add-deal-btn(v-if="isDetailReadonly" @click="isDetailReadonly = false" width="34" height="34" min-width="34" :ripple="false" color="success" depressed dark fab)
            v-icon(size="16") icon-add
          v-form(v-model="valid", ref="form")
            v-combobox.ml-3.child-case-combobox(v-if="!isDetailReadonly" v-model = "childCase" :items="addChildCaseList" item-text="subject" item-value="id" :search-input.sync="search_child_case" required :loading='combo_loading' :label=`$t('CHILD_CASE.ADD_CHILD_CASE')` @blur='clearData()')
                template(v-slot:item='data')                          
                    .t-black {{ data.item.subject }} 
                template(v-slot:selection='data')
                    .t-black {{ data.item.subject }}  
                template(v-slot:no-data)
                  v-list-item
                    .t-black(v-if='isSearchNotFound') {{$t('CHILD_CASE.NO_CHILD_CASE')}}
                    .t-black(v-else) {{$t('CHILD_CASE.SEARCH_CHILD_CASE')}}
          v-btn.mr-3.v-btn-save(v-if="!isDetailReadonly" width="70" text color="success" @click="confirmAddChildCase()")
            v-icon(size="20") icon-check
            span {{$t('GENERAL.SAVE')}}    
          v-btn.mr-3(v-if="!isDetailReadonly" width="70" text color="gary" @click="isDetailReadonly = true; childCase = null")
            v-icon(size="20") icon-cancel
            span {{$t('GENERAL.CANCEL')}}               
      v-col.text-right(cols="3").d-flex.align-center
        .btn-group.ml-auto.mr-2
          v-text-field(v-model="searchChildCase" flat dense solo prepend-inner-icon="icon-search" hide-details)
      v-col.text-right(cols="1").d-flex.align-center
        v-btn.ml-auto( :ripple="false" @click="onCancel" depressed="depressed" icon)
          v-icon icon-cancel
  v-card-text.pt-4.px-5.pb-5
    .data-area
      v-data-table.border-table(:headers="tableCaseHeader" :items="childCaseData" :items-per-page="childCasePerPage" :page.sync="childCasePage" :search="searchChildCase" multi-sort :header-props="{sortIcon: 'mdi mdi-arrow-up'}" @page-count="childCasePageCount = $event" hide-default-footer="hide-default-footer" :loading='list_loading' loading-text='loading...')
        template(v-slot:item.subject="{ item }")
            .ellipsis
              a(:href="'/Case/' + item.id") {{ item.subject }}
        template(v-slot:item.primary_contact.__chineseName__="{ item }")
            .ellipsis
              a.pl-2.ellipsis(:title='item.primary_contact.__chineseName__' :href='"/Contacts/" + item.primary_contact.id') {{ item.primary_contact.__chineseName__ }}  
        template(v-slot:item.priority_number="{ item }")
          v-icon(v-if="item.priority_number == '5'" :title='item.priorityName' size='20' color='red') fas fa-angle-double-up
          v-icon(v-if="item.priority_number == '4'" :title='item.priorityName' size='20' color='red') fas fa-angle-up
          v-icon(v-if="item.priority_number == '3'" :title='item.priorityName' size='20' color='orange') fas fa-equals
          v-icon(v-if="item.priority_number == '2'" :title='item.priorityName' size='20' color='primary') fas fa-angle-down
          v-icon(v-if="item.priority_number == '1'" :title='item.priorityName' size='20' color='primary') fas fa-angle-double-down
        template(v-slot:item.statusName='{ item }')
            v-chip.my-2.text-white(v-if='item.statusName == $i18n.t("CASESTATUSLIST.RESOLVED")' color='green' small) {{ item.statusName }}
            v-chip.my-2.text-white(v-else-if='item.statusName == $i18n.t("CASESTATUSLIST.CLOSED")' color='primary' small) {{ item.statusName }}
            v-chip.my-2.text-black(v-else small) {{ item.statusName }}
        template(v-slot:item.groupName='{ item }')
            .ellipsis
                span(v-if='item.group' :title='item.groupName') {{ item.groupName }}
        template(v-slot:item.typeName='{ item }')
            .ellipsis
                span(v-if='item.type' :title='item.typeName') {{ item.typeName }}
        template(v-slot:item.owner.fullName='{ item }')
            .ellipsis
                a.pl-2.ellipsis(:href='onMail(item.owner.email)' :title='item.owner.fullName') {{ item.owner.fullName }}
        template(v-slot:item.active='{ item }')
          v-btn(icon='icon', @click='onDelete(item)')
            v-icon(size="18") icon-remove
      v-pagination.mt-3(
        v-model="childCasePage",
        :length="childCasePageCount",
        circle="circle"
        :total-visible="10"
      )
  v-dialog(v-model="messageDialog" width="360" content-class="statusDialog")
    message-dialog(@emitMessageDialog="onEmitMessageDialog" :message="message")
  v-dialog(v-model="successDialog" width="360" content-class="statusDialog")
    success-dialog(@emitSuccessDialog="onEmitSuccessDialog")
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog()')
    error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import i18n from '/common/plugins/vue-i18n.js' 
//-dialog
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
//-service
import CaseDataService from "@/services/CaseDataService";
import StageDataService from "@/services/StageDataService"
//-list
import caseStatusList from "@/array/the_caseStatusList";
import casePriorityList from "@/array/the_casePriorityList";

export default Vue.extend({
    props: {
      childCaseData: {
        type: Array,
        required: true,
      },
      parentCaseId: {
        type: String,
        required: false,
      }
    },
    components: {
      messageDialog,
      successDialog,
      errorDialog
    },
    async created() {
      this.caseId = this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id');
      StageDataService.stageList('case')
      .then(response => {
        this.stageTypeList = response.data;
        if(this.stageTypeList.length == 0)
        {
            let item = {};
            item.stage_type_name = i18n.t('CASE.NO_CASE_STAGE');
            this.stageTypeList.push(item);
        }
      })

      await CaseDataService.getcasegrouplist()
        .then(response => {
          this.caseGroupList = response.data;
      });

      await CaseDataService.getcasetypelist()
        .then(response => {
          this.caseTypeList = response.data;
      });
      this.list_loading = false
    },
    data() {
      return {
        //-other
        subjectRules: [(v) => !!v || i18n.t('RULE.RULE_R_Case')],
        caseId:'',
        valid: true,
        isDetailReadonly: true,
        isSearchNotFound:false,
        //-table
        tableCaseHeader: [
          {
            text: i18n.t('CHILD_CASE.SUBJECT'),
            value: "subject",
            align: "center",
            sortable: true,
          },
          {
            text: i18n.t('CHILD_CASE.CONTACT'),
            value: "primary_contact.__chineseName__",
            align: "center",
            sortable: true,
          },
          {
            text: i18n.t('CHILD_CASE.PRIORITY'),
            value: "priority_number",
            align: "center",
            sortable: true,
          },
          {
            text: i18n.t('CHILD_CASE.STATUS'),
            value: "statusName",
            align: "center",
            sortable: true,
          },
          { 
          text: i18n.t('CHILD_CASE.GROUP'), 
          value: "groupName", 
          align: "center", 
          sortable: true,
          },   
          { 
          text: i18n.t('CHILD_CASE.TYPE'), 
          value: "typeName", 
          align: "center", 
          sortable: true,
          },     
          { 
          text: i18n.t('CHILD_CASE.OWNER'), 
          value: "owner.fullName", 
          align: "center", 
          sortable: true,
          }, 
          { 
          text: i18n.t('CHILD_CASE.CLOSED_DATE'), 
          value: "closed_time", 
          align: "center", 
          sortable: true,
          width:'13%',
          },
          { 
          text: i18n.t('CHILD_CASE.ACTIVE'), 
          value: "active", 
          align: "center", 
          sortable: false,
          },
        ],
        childCasePage: 1,
        childCasePageCount: 0,
        childCasePerPage: 8,
        searchChildCase: '',
        //-dialog
        messageDialog: false,
        successDialog: false,
        errorDialog: false,
        message: '',
        errorMessage: '',
        //-list
        caseStatusList: caseStatusList,
        casePriorityList: casePriorityList,
        caseGroupList: [],
        caseTypeList: [],
        stageTypeList: [],
        addChildCaseList: [],
        search_child_case : null,
        //-object
        childCase: null,
        deleteChildCase:'',
        //-loading
        list_loading: true,
        combo_loading: false,        
      }
    },
    methods: {
      validateForm() {
        if (this.$refs.form.validate()) {
          if(typeof(this.childCase) == 'object')
            return true;
          else
            return false;
        }
        else {
          return false
        }
      },
      checkCaseIsObject() {
        if (this.childCase != null && typeof(this.childCase) == 'object') {
          return true
        }
        else {
          this.errorDialog = true
          this.errorMessage = i18n.t('CHILD_CASE.ERROR')
        }
      },
      getFormatTime(data){
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
        let today  = data;
        if(this.$i18n.locale == "zh_TW")
        {
            return today.toLocaleDateString("zh-TW", options); 
        }else
        {
            return today.toLocaleDateString("en-US", options);
        }                
      },
      onMail: function (mail) {
        return 'mailto:'+ mail;
      },
      showMessageDialog(message) {
        this.messageDialog = true;
        this.message = message;
      },
      onEmitMessageDialog(val) {
        this.messageDialog = false;
        if(val) {
          delete this.deleteChildCase['owner']['usersetting']
          this.deleteChildCase['parent_case_id'] = null
          CaseDataService.updateparentcase(this.deleteChildCase)
          .then((response)=>{
            let delIndex = this.childCaseData.findIndex(n => n.id == response.data.id);
            this.childCaseData.splice(delIndex, 1);
            this.successDialog = true;
          })
        }
      },    
      onEmitSuccessDialog() {
        this.successDialog = false;
      },
      onEmitErrorDialog() {
        this.errorDialog = false;
      },
      onCancel() {
        this.$emit("emitChildCasesDialog", false);
        this.$emit("emitChildCases", this.childCaseData);
      },
      onDelete(childCase) {
        this.showMessageDialog(i18n.t('CHILD_CASE.MESSAGE'));
        this.deleteChildCase = childCase;
      },
      async confirmAddChildCase() {
        if(this.validateForm() && this.checkCaseIsObject()) {
          delete this.childCase['owner']['usersetting']
          this.childCase['parent_case_id'] = this.caseId
          CaseDataService.updateparentcase(this.childCase)
          .then(response=>{
            this.successDialog = true;
            this.UpdateCaseData(response.data)
            response.data.priority_number = this.getPriorityNumber(response.data.priority)
            this.childCaseData.unshift(response.data)
          })
        }
        this.isDetailReadonly = true;
        this.childCase = null;

      },
      UpdateCaseData(item) {
        item.priorityName = this.getItemNameFromList(this.casePriorityList, item.priority)
        item.statusName = this.getItemNameFromList(this.caseStatusList, item.status)
        item.groupName = this.getItemFromListById(this.caseGroupList, item.group).group_name;
        item.typeName = this.getItemFromListById(this.caseTypeList, item.type).type_name;
        item.owner.fullName = item.owner.first_name != '' ? item.owner.last_name + ' ' + item.owner.first_name : item.owner.last_name;
        if (item.closed_date != null) {
            item.closed_time = this.getFormatTime(new Date(item.closed_date));
        }
      },
      clearData() {
        this.addChildCaseList = []
        this.isSearchNotFound = false
      }
    },
    watch: {
      async search_child_case(val) {
        // check user input is en / zh / num
        let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
        let isEnZhNum = false;
        pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

        // check user input data type
        let isString; 
        typeof(val) == 'string' ? isString = true : isString = false;

        // search
        if(isEnZhNum && !!val && isString) {
          this.combo_loading = true;
          this.isSearchNotFound = false
          await CaseDataService.kanabn('list', 'all', 0, 1, val,9999,'','')
          .then(response => {
              this.addChildCaseList = response.data.list
              this.addChildCaseList = this.addChildCaseList.filter((item) => {
                if(item.subject.includes(val)) {
                  return item.id != this.caseId && item.parent_case_id == null && item.id != this.parentCaseId
                }
              })
          })
          .finally(() => {
              if(this.addChildCaseList.length < 1) {
                this.isSearchNotFound = true
              }
              this.combo_loading = false;
          })
      }
      }
    }
})

</script>
<style>
.child-case-combobox{
    width: 200px !important;
    height: 50px !important;    
}
.v-btn-save {  
  float: right; 
}
</style>