<template lang="pug">
    v-card.caseDialog
        v-card-title.d-flex.justify-center {{$t('ADD_CASE.TITLE')}}
        v-card-text
            v-form(v-model='addCaseValid' ref='form')  
                v-text-field.mb-4(v-if='!useAutoNumber' v-model='addCase.subject' :label=`$t('ADD_CASE.NAME')` :rules='formRules.nameRule' required='required' large='large' validate-on-blur )
                v-text-field.mb-4(v-else v-model='addCase.subject' :label=`$t('ADD_CASE.NAME')` :rules='formRules.nameRule' required='required' large='large' validate-on-blur :disabled='useAutoNumber')
                v-combobox.no-border.height-48(v-if="!isNewContact" v-model="addCase.primary_contact" :items="contactList" @blur='contactList = []' :search-input.sync="contactSearch" :menu-props="{openOnClick: true}" :no-data-text=`$t('ADD_CASE.NO_CONTACT')` item-text="searchStr" item-value="id" hide-details="auto" prepend-icon='mdi-account-multiple' :label=`$t('ADD_CASE.CONTACT')` :rules='formRules.contactRules' :loading='contact_loading')
                    template(v-slot:prepend-item)
                        v-list-item
                            v-btn(width="120" height="32" color="blue" plain border-0 @click="isNewContact = true")
                                v-icon.mr-1(size="14") icon-add
                                span {{$t('ADD_CASE.NEW_CONTACT')}}
                    template(v-slot:item='data')
                        v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                            v-icon(v-if="data.item.image == null") mdi-account
                            img(v-else :src='data.item.image')
                        .t-black {{ data.item.full_name }} 
                        .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                    template(v-slot:selection='data')
                        .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                v-row(no-gutters="no-gutters")        
                    v-col(cols="6")
                        v-text-field.ml-3(v-if="isNewContact" v-model="newContact.last_name" :label= `$t('ADD_CONTACT.LAST_NAME')` large="large" :rules='formRules.contactNameRules')
                    v-col(cols="6")
                        v-text-field.ml-3(v-if="isNewContact" v-model="newContact.first_name" :label=`$t('ADD_CONTACT.FIRST_NAME')` large="large" :rules='formRules.contentLenRule50')
                    v-col(cols="6")
                        v-btn.w-100.justify-start.px-0(v-if="isNewContact" text color="blue" plain border-0 @click="isNewContact = false")
                            v-icon(size="20") mdi-keyboard-return 
                            span.ml-4 {{$t('ADD_CASE.OLD_CONTACT')}}
                v-combobox.no-border.height-48(v-model='addCase.case_org' :items='organizationList' item-text='name' item-value="uqNumber" :loading="isLoading" :search-input.sync="search" prepend-icon='mdi-domain' hide-details="auto" :label=`$t('ADD_CASE.ORG')` :rules='formRules.companyRule')
                    template(v-slot:append-outer)
                        div(v-if="isNew")
                            v-icon(color="red", size="25") mdi-new-box
                    template(v-slot:item="data")
                        div.ellipsis(v-if="data.item.id == 0")
                            span {{ data.item.name }}
                            v-icon(color="red", size="25") mdi-new-box
                        div.ellipsis(v-else)
                            span {{ data.item.name }}
                v-row.mt-1.pt-3(no-gutters="no-gutters")
                    v-col(cols="1")
                        v-icon(size='20') mdi-cellphone
                    v-col(cols="6")
                        vue-tel-input-vuetify.no-border.height-48(v-model="addCase.incoming_phone" :label=`$t('ADD_CASE.PHONE')`  placeholder='' autocomplete='off' :defaultCountry='getLocation()' disabledFetchingCountry=true mode='international' @validate="onPhoneCheck($event)" @input="onPhoneInput" :rules="formRules.phoneRules")
                //- v-text-field.no-border.height-48(v-model='addCase.incoming_phone' :label=`$t('ADD_CASE.PHONE')` :rules='formRules.phoneRules' prepend-icon='mdi-cellphone' large='large')
                v-text-field.no-border.height-48.pt-0(v-model='addCase.incoming_email' :label=`$t('ADD_CASE.EMAIL')`, :rules='formRules.emailRules' prepend-icon='mdi-email' large='large')
                v-row(no-gutters="no-gutters")
                    v-col(cols="6")
                        v-select.no-border.height-48(v-model='addCase.source' solo :items='caseSourceList' prepend-icon='fa-solid fa-arrow-right' item-text='name' item-value='key' :label= `$t('ADD_CASE.SOURCR')`)
                    v-col(cols="6")
                        v-select.no-border.height-48(v-model='addCase.group' solo :items='caseGroupList' prepend-icon='fa-solid fa-clipboard' item-text='group_name' item-value='id' :label= `$t('ADD_CASE.GROUP')`)
                v-row(no-gutters="no-gutters")
                    v-col(cols="6")
                        v-select.no-border.height-48(v-model='addCase.type' solo :items='caseTypeList' prepend-icon='fa-solid fa-stamp' item-text='type_name' item-value='id' :label= `$t('ADD_CASE.TYPE')`)
                    v-col(cols="6")
                        v-combobox.no-border.height-48(v-model='addCase.parent_case' :items='caseParentCaseList' @blur='caseParentCaseList=[]' item-text='subject' item-value="id" :loading='parent_case_list_loading' :search-input.sync="search_parent_case" prepend-icon='mdi-wrench' hide-details="auto" :label=`$t('ADD_CASE.PARENT_CASE')`)
                            template(v-slot:item="data")
                                div.ellipsis
                                    span {{ data.item.subject }}
                v-menu(ref="menu" v-model="menu" :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                    template(v-slot:activator="{ on, attrs }")
                        v-text-field.no-border.height-48(v-model='addCase.due_date'  v-bind="attrs" v-on="on" prepend-icon='mdi-calendar'  hide-details="auto" :label= `$t('ADD_CASE.EXPECTED_CLOSE_DATE')` :rules='formRules.dateRule')
                    v-date-picker(v-model='addCase.due_date' @input='menu = false' no-title scrollable)
                v-combobox.no-border.my-0.py-0.mt-6(:label= `$t('ADD_CASE.SELECT')` v-model='addCase.stage_type' :items='stageList' item-text='stage_type_name' @change='selectStageType()' prepend-icon='mdi-sync' hide-details="auto" flat required='required' validate-on-blur :rules='formRules.stageTypeRule')
                div(style='height: 40px;')
                    .my-2.d-flex.justify-center.align-center.w-100.h-100(v-if='!isProcess && addCase.stage_type != ""')
                        v-progress-circular(indeterminate size='20')
                    v-stepper.process-box.my-2(v-if='isProcess && process.length > 0' v-model='currentStage')
                        v-stepper-header.process-bar
                            template(v-for='item in process')
                                v-stepper-step(:key='`${item.sequence_number}`' :complete='currentStage>item.sequence_number' :step='item.sequence_number' :class='`customcolor`' :style='getcolor(item.stage_color)' edit-icon='icon-check' editable)
                                    span {{ item.stage_name }}
                            v-btn(v-if='lenProcess > 3 && process[0].sequence_number != 1' absolute style="marginTop: 4px; zIndex:1000;" class="mx-1" fab dark x-small color="white" @click='preStage()')
                                v-icon(color="primary") icon-arrow-left
                            v-btn(v-if='lenProcess > 3 && process[2].sequence_number != lenProcess' absolute style="marginLeft: 93.5%; marginTop: 4px; zIndex:1000;"  fab dark x-small color="white" @click='nextStage()')
                                v-icon(color="primary") icon-arrow-right
                    v-stepper.process-box.my-2(v-if='isProcess && process.length == 0 || addCase.stage_type == ""')
                        v-stepper-header.process-bar.lightGrey.rounded-pill.d-flex.justify-center.align-center
                            span(style='color: rgba(0, 0, 0, 0.38);') {{$t('ADD_CASE.PROCESS')}}
                v-window
                    customFieldRequired(v-if='isProcess' ref='CFRequired' :editData='JSON.stringify(this.addCase.custom_field)' DataClass = 'Case'  :StageTypeId='passId' :stageList='stageList').mt-5

        v-card-actions.px-2.py-4
            v-row
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark)
                        v-progress-circular(v-if='addBtn_loading' indeterminate size='20')
                        .t-white(v-else) {{$t('GENERAL.ADD')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import ContactDataService from "@/services/ContactDataService";
    import OrganizationDataService from "@/services/OrganizationDataService";
    import StageDataService from "@/services/StageDataService";
    import CaseDataService from "@/services/CaseDataService";
    import errorDialog from "@/components/Dialog/errorDialog";
    import caseSourceList from "@/array/the_caseSourceList";
    import i18n from '/common/plugins/vue-i18n.js';
    import customFieldRequired from "@/components/Dialog/customFieldRequired";
    import AutoNumberService from "@/services/AutoNumberService";

    export default Vue.extend({
        props: {
            addCase: {
                type: Object,
                required: true,
            }, 
        },
        components: {
            errorDialog,
            customFieldRequired,
        },
        async created(){
            this.checkAutoNumber();
            CaseDataService.getcasegrouplist()
            .then(response => {
                this.caseGroupList = response.data;
            })
            .catch(response => {
                this.showErrorDialog(response);
            });

            CaseDataService.getcasetypelist()
            .then(response => {
                this.caseTypeList = response.data;
            })
            .catch(response => {
                this.showErrorDialog(response);
            });

            CaseDataService.getcasecategorylist()
            .then(response => {
                this.caseCategoryList = response.data;
            })
            .catch(response => {
                this.showErrorDialog(response);
            });
            
            await StageDataService.stageList("case")
            .then(response => {
                this.stageList = response.data;
                this.currentStage = 1;
            })
            .finally(() => (this.isLoading = false));

            if(!this.addCase.parent_case)
            {
                this.addCase.parent_case = null;
            }
            if (this.addCase.stage_type != null && this.addCase.stage_type.id!="all") 
            {
                this.allProcess = this.stageList.filter(s => s.id == this.addCase.stage_type.id)[0].stagedef_set;
                this.passId = this.addCase.stage_type.id; 
                if (this.addCase.current_stage == undefined) 
                {
                    if (this.addCase.stage_type.stagedef_set == undefined) 
                    {
                        this.currentStage = 1;
                    } 
                    else 
                    {
                        this.currentStage = this.addCase.stage_type.stagedef_set.sequence_number;
                    }
                } 
                else if (this.addCase.current_stage == 0) 
                {
                    this.currentStage = 1;
                } 
                else 
                {
                    this.currentStage = this.addCase.current_stage;
                }
            } 
            else 
            {
                let default_process = null;
                try 
                {
                    default_process = this.user.default_value.default_process;
                } 
                catch (error) 
                {
                    default_process = null;
                }

                if(default_process == null || default_process.case == null)
                {
                    this.stageList.forEach(e => {
                        e.is_admin_default == true ? this.addCase.stage_type = e : '';
                    })
                }
                else
                {
                    this.stageList.forEach(e => {
                        e.id == default_process.case ? this.addCase.stage_type = e : '';
                    })
                }
                this.currentStage = 1;
            }
            this.selectStageType("new");
        },
        data() {
            return {
                isLoading: false,
                addCaseValid: true,
                formRules: {
                    nameRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_TITLE'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
                    ],
                    dateRule: [(v) => !!v || i18n.t('RULE.RULE_DUE_DATE'),],
                    stageTypeRule: [(v) => !!v || i18n.t('RULE.RULE_PROCCESS')],
                    forecastRule: [(v) => !!v || i18n.t('RULE.RULE_FORECAST')],
                    contactRules: [(v) => !!v || i18n.t('RULE.RULE_R_Case_Contact')],
                    phoneRules: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
                    emailRules: [
                        (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|(^$)|null/.test(v) || i18n.t('RULE.RULE_EMAIL'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
                    ],
                    companyRule: [
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                        (v) => /^(?!\s*$).+|(^$)|null/.test(v) || i18n.t('RULE.RULE_NULL'),
                    ],
                    contactNameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
                    ],
                    contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                },
                allProcess: [],
                process: [],
                contactList: [],
                organizationList: [],
                stageList: [],
                caseGroupList: [],
                caseTypeList: [],
                caseCategoryList: [],
                caseParentCaseList:[],
                comboText: '',
                search: null,
                menu: false,
                isNewContact: false,
                newContact: {
                    last_name: '',
                    first_name: '',
                },
                errorDialog: false,
                errorMessage: '',
                isProcess: false,
                isContact: true,
                currentStage: 1,
                lenProcess: '',
                addBtn_loading: false,
                caseSourceList: caseSourceList,
                stage_type_data: {},
                contactSearch: null,
                contact_loading: false,
                user: JSON.parse(window.localStorage.getItem('user')),
                search_parent_case : null,
                parent_case_list_loading: false,
                comboInput_parent_case : null,
                passId:'',
                cfValid:true,
                // mobile phone
                isNumVaild: true,
                vue_tel: {
                    isValid: true,
                },
                useAutoNumber:false,
            }
        },
        methods: {
            checkAutoNumber(){
                AutoNumberService.list().then(response => 
                {
                    let caseAutoNumber = response.data.filter(el => {return el.using_page == "CASE"})[0];
                    if(caseAutoNumber.active){
                        this.useAutoNumber = caseAutoNumber.autofill_title;
                    }else{
                        this.useAutoNumber = false;
                    }
                    if(this.useAutoNumber){
                        this.addCase.subject = i18n.t('ADD_CASE.USE_AUTONUMBER');
                    }
                })
                .catch((response) => {
                    this.showErrorDialog(response);
                });
            },
            validateForm(){
                if(this.$refs.form.validate())
                {
                    return true;
                }
                else
                {
                    return false;
                }
            },
            getResponsedata(){
                this.$refs.CFRequired.emitData();
                if(this.$refs.CFRequired.responsedata === false)
                {
                    this.$refs.CFRequired.responsedata = null;
                    this.cfValid = false;
                    return;
                }
                this.addCase.custom_field = this.$refs.CFRequired.responsedata;
            },
            async onConfirm() {
                this.formRules.dateRule.push((v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE'));
                this.formRules.phoneRules.push(() => {return (this.isNumVaild || i18n.t('CASE_DETAIL.ERROR'))});
                if (await this.validateForm() && this.addBtn_loading == false) 
                {
                    if((this.addCase.custom_field != null && this.addCase.custom_field !== undefined) || this.$refs.CFRequired.process_array.length != 0)
                        this.getResponsedata();
                    if(this.cfValid == false)
                    {
                        this.cfValid = true;
                        return;
                    }
                    if (typeof(this.addCase.stage_type) !== 'object') 
                    {
                        this.showErrorDialog(i18n.t('ADD_CASE.ERROR'));
                        return;
                    }
                    
                    let case_org = this.addCase.case_org;
                    let primary_contact = this.addCase.primary_contact;
                    let new_contact_lastName = this.newContact.last_name;

                    if (this.comboText == '') 
                    {
                        case_org = {};
                    } 
                    else if (typeof(case_org) == 'string') 
                    {
                        case_org = {
                            id: -1,
                            name: case_org,
                            company_number: '',
                        }
                    }

                    if (this.isNewContact == true) 
                    {
                        if (new_contact_lastName == '') 
                        {
                            this.isContact = false;
                            primary_contact = {};
                        }
                        else 
                        {
                            this.isContact = true;
                            primary_contact = {
                                id: 0,
                                last_name: this.newContact.last_name,
                                first_name: this.newContact.first_name != null ? this.newContact.first_name : "",
                            }                       
                        }
                    }
                    else 
                    {
                        if (primary_contact == '' || primary_contact == null) 
                        {
                            this.isContact = false;
                            primary_contact = {};
                        }
                        else if (typeof(primary_contact) === 'string') 
                        {
                            this.showErrorDialog(i18n.t('ADD_CASE.ERROR_2'));
                            return;
                        }
                        else 
                        {
                            this.isContact = true;
                        }
                    }

                    if (!this.isContact && this.comboText == '') 
                    {
                        this.showErrorDialog(i18n.t('ADD_CASE.ERROR_3'));
                        this.isContact = true;
                        return;
                    }
                    else
                    {
                        this.addBtn_loading = true;
                    }
                    if(this.addCase.parent_case != null && this.addCase.parent_case != '') 
                    {
                        if(this.comboInput_parent_case != this.addCase.parent_case.subject) 
                        {
                            this.showErrorDialog(i18n.t('ADD_CASE.ERROR_4'));
                            this.addBtn_loading = false;
                            return;
                        }
                    }
                    else
                    {
                        this.addCase.parent_case = null;
                    }
                    let addCaseData = {};
                    addCaseData = {
                        subject: this.addCase.subject,
                        primary_contact: primary_contact,
                        case_org: case_org,
                        source: this.addCase.source,
                        group: this.addCase.group,
                        type: this.addCase.type,
                        category: this.addCase.category,
                        due_date: this.addCase.due_date,
                        stage_type: this.addCase.stage_type.id,
                        current_stage: this.allProcess[this.currentStage - 1],
                        incoming_phone: this.addCase.incoming_phone,
                        incoming_email: this.addCase.incoming_email,
                        opptys:this.addCase.opptys? this.addCase.opptys:[],
                        custom_field:this.addCase.custom_field? this.addCase.custom_field:null,
                        parent_case_id: this.addCase.parent_case != null ? this.addCase.parent_case.id : null,
                    }
                    
                    await CaseDataService.create(addCaseData)
                    .then(response => {
                        let newCase = response.data;
                        this.addCase.id = response.data.id;
                        if(this.addCase.case_org != null)
                        {
                            if (this.addCase.case_org.id == 0) 
                            {
                                if (response.data.case_org != null) 
                                {
                                    let principalData = {
                                        "organization_id": response.data.case_org.id,
                                        "owner_id": response.data.owner.id,
                                    };
                                    ContactDataService.createCompanyPrincipal(principalData);
                                }
                            }
                        }
                        this.addBtn_loading = false;
                        newCase.current_stage_id = addCaseData.current_stage.id;
                        newCase.priority_number = this.getPriorityNumber(newCase.priority);
                        this.$emit('emitAddCaseDialog', true);
                        this.$emit('emitNewCaseData', newCase);
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    });
                }
            },
            onCancel() {
                this.$emit('emitAddCaseDialog', false);
            },
            async selectStageType(type) {
                try 
                {
                    if (this.addCase.stage_type.stagedef_set.length != undefined) 
                    {
                        this.allProcess = this.addCase.stage_type.stagedef_set;
                        this.passId = this.addCase.stage_type.id;
                    }
                    this.allProcess.sort((a, b) => {
                        return a.sequence_number - b.sequence_number;
                    });
                    this.isProcess = true;
                    this.lenProcess = this.allProcess.length;
                    await this.setProcess();
                    if(type === undefined)
                        this.currentStage = 1;
                    // await (this.currentStage = this.currentStage - 1);
                } 
                catch 
                {
                    this.isProcess = false;
                    return;
                }
            },
            setProcess() {
                if (this.lenProcess > 3) 
                {
                    if (this.currentStage <  3) 
                    {
                        this.process = this.allProcess.slice(0, 3);
                    } 
                    else if (this.currentStage > this.lenProcess - 2) 
                    {
                        this.process = this.allProcess.slice(this.lenProcess - 3, this.lenProcess);
                    } 
                    else 
                    {
                        this.process = this.allProcess.slice(this.currentStage - 2, this.currentStage + 1);
                    }
                }
                else 
                {
                    this.process = this.allProcess;
                }
                // this.currentStage = this.currentStage + 1;
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            getcolor: function(data) {
                this.colorQ= data;	 
                return {
                    '--backgroundColor':this.colorQ,
                    '--color': this.colorQ,
                };
            },
            async nextStage() {
                if (this.process[2].sequence_number == this.lenProcess) 
                {
                    return;
                } 
                else 
                {
                    let n = this.process[2].sequence_number;
                    n = n + 2;
                    await this.changeAddStage(n);
                    await (this.currentStage = this.currentStage - 1);
                }
            },
            async preStage() {
                if (this.process[0].sequence_number == 1) 
                {
                    return;
                } 
                else 
                {
                    let n = this.process[0].sequence_number;
                    n = n - 2;
                    await this.changeMinusStage(n);
                    await (this.currentStage = this.currentStage - 1);
                }
            },
            changeAddStage(n) {
                if (n > this.lenProcess - 2) 
                {
                    this.process = this.allProcess.slice(this.lenProcess - 3, this.lenProcess);
                } 
                else 
                {
                    this.process = this.allProcess.slice(n - 2, n + 1);
                }
                this.currentStage = this.currentStage + 1;
            },
            changeMinusStage(n) {
                if (n < 3) 
                {
                    this.process = this.allProcess.slice(0, 3);
                }
                else 
                {
                    this.process = this.allProcess.slice(n - 2, n + 1);
                }
                this.currentStage = this.currentStage + 1;
            },
            onPhoneCheck(event) {
            this.vue_tel = event;
            if (this.vue_tel.isValid == true) 
            {
                this.isNumVaild = true;
            }
            },
            onPhoneInput() {
                if (this.isNumVaild == true) 
                {
                    if (this.addCase.incoming_phone != this.vue_tel.number.input && this.addCase.incoming_phone != "") 
                    {
                        this.isNumVaild = false;
                    }
                } 
                else 
                {
                    if (this.addCase.incoming_phone == "") 
                    {
                        this.isNumVaild = true;
                    }
                }
            },
        },
        computed: {
            isNew: function(){
                if(this.addCase.case_org == " " || this.addCase.case_org == null)
                {
                    return false;
                }
                if(typeof (this.addCase.case_org) === 'object')
                {
                    if(this.addCase.case_org.id == 0)
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }
                else
                {
                    return true;
                }
            },
        },
        watch: {
            async search(val) {
                var re = /^(?!\s*$).+/;
                var check = re.test(val);
                var checkLength = /^.{0,200}$/.test(val);
                this.comboText = "";
                if(check && val != null && checkLength)
                {
                    let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                    this.comboText = val;
                    if (!this.isLoading && charNum>=2 && charNum==val.length ) 
                    {
                        this.isLoading = true;
                        await OrganizationDataService.findOrganizationByNamefromDB(val)
                        .then(response => { 
                            this.organizationList = response.data; 
                            let num = 0;
                            this.organizationList.forEach((item)=>{
                                item.uqNumber = num;
                                num ++;
                            })
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                    }
                    else 
                    {
                        this.isLoading = false;
                    }
                }
            },
            "addCase.primary_contact":function(){
                if(this.addCase.primary_contact == '' || this.addCase.primary_contact == null)
                {                    
                    this.addCase.incoming_phone = '';
                    this.addCase.incoming_email = '';
                }
                else
                {
                    if (this.addCase.primary_contact.mobile_phone == null) 
                    {
                        this.addCase.incoming_phone = '';
                    } 
                    else 
                    {
                        this.addCase.incoming_phone = this.addCase.primary_contact.mobile_phone;
                    }

                    if (this.addCase.primary_contact.email == null) 
                    {
                        this.addCase.incoming_email = '';
                    } 
                    else 
                    {
                        this.addCase.incoming_email = this.addCase.primary_contact.email;
                    }
                }
            },
            "isNewContact":function(){                 
                    this.addCase.primary_contact = '';       
                    this.addCase.incoming_phone = '';
                    this.addCase.incoming_email = '';                
            },
            contactSearch(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array = [];

                // search
                if(isEnZhNum && !!val && isString)
                {
                    this.contact_loading = true;
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh;
                            if(item.searchStr.includes(val))
                            {
                                if(item.organization_set.length > 0)
                                {
                                    item.org_name = "[ "+item.organization_set[0].name +" ]";
                                }
                                array.push(item);
                            }
                        })
                        this.contactList = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.contact_loading = false;
                    })
                }
                else{
                    this.contactList = [];
                }
            },
            async search_parent_case(val) {
                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                // search
                if(isEnZhNum && !!val && isString) 
                {
                    this.parent_case_list_loading = true;
                    this.comboInput_parent_case = val;

                    await CaseDataService.kanabn('list', 'all', 0, 1, val,9999,'','')
                    .then(response => {
                        this.caseParentCaseList = response.data.list;
                    })
                    .finally(() => {
                        this.parent_case_list_loading = false;
                    })
                }
                else
                {
                    this.comboInput_parent_case = null;
                }
            }
        },
    });
</script>

<style scoped>
.process-bar {
    width: calc(100% );
    margin-left: 0px;
}
</style>