<template lang="pug">
    v-card.to-deal-dialog(v-click-outside="onClickOutside")
        v-overlay(v-show='loading_caseDeatil' opacity='0' z-index='999')
        v-card-title.d-block.pt-5
         v-form(v-model='addDealValid' ref='form')
            v-row.d-flex.align-center.mb-6(no-gutters)
                v-col(v-if='!permitted' cols='11')
                    v-icon(size='30' color='red') icon-error
                    label {{$t('GENERAL.AUTH')}}    
                v-col(v-if='permitted' cols='8')
                    v-skeleton-loader(v-show='!isCreated & !isEdit' type="list-item-avatar")  
                    v-row(no-gutters v-if='!isEdit && isCreated')
                        v-col(:cols='caseNameWidth' ref="nameWidth")
                            h2.mb-0.ellipsis(v-show='isCreated & !isEdit' id="nameWidthH2") {{ caseDetail.subject }} 
                        v-col(cols='1')
                            v-btn.ml-3(width='70' text color='gary' @click='onEdit' v-if='!isEdit && open')
                                v-icon(size='20') icon-edit
                                span {{$t('GENERAL.EDIT')}} 
                    v-row(v-if='isEdit')
                        v-col.pa-2(cols='6')
                            v-text-field.opptyNameFieldInput(v-model='caseDetail.subject' :rules="formRule.nameRules" v-if='isEdit' width='400px')
                        v-col.pa-2(cols='6').opptyNameField
                            v-btn(width='70' text color='gary' @click='cancelEdit()' v-if='isEdit')
                                v-icon(size='20') icon-cancel
                                span {{$t('GENERAL.CANCEL')}}
                            v-btn.ml-3(width='70' text color='success' @click='confirmEdit()' v-if='isEdit')
                                v-icon(size='20') icon-check
                                span {{$t('GENERAL.SAVE')}}
                            v-progress-circular(size='18' v-if='loading_caseDeatil' indeterminate color='primary' class='ml-3')
                v-col.text-right(cols='4')
                    v-chip(v-if='isProcessLost' color='red' text-color='white') {{ closeReasonName }} {{$t('TO_CASE.IN')}} {{ caseDetail.closed_date }} {{$t('TO_CASE.CLOSED')}}
                    v-chip(v-if='isProcessWon' color='green' text-color='white') {{$t('TO_CASE.SOLVE')}} {{$t('TO_CASE.IN')}} {{ caseDetail.closed_date }} {{$t('TO_CASE.WIN')}}
                    v-btn(:ripple='false' @click='onCancel' depressed='depressed' icon)
                        v-icon icon-cancel
                    
            v-skeleton-loader(v-if='permitted' v-show='!isCreated' type="table-heading, list-item-two-line")
            v-card.card-preface.px-0.py-2(flat v-if='permitted' v-show='isCreated')
                v-row.pa-0
                    v-col.pa-2.pa-md-3.ellipsis(cols='6' md='3' v-if='!isEdit')
                        v-card-subtitle.pa-0.t-lightgary {{$t('TO_CASE.NAME')}}
                        a.f-text-highlight.mb-0( :href="'/Contacts/' + contactId") {{ contactName }}
                    v-col.pa-2.pa-md-3.ellipsis(cols='6' md='3' v-if='!isEdit')
                        v-card-subtitle.pa-0.t-lightgary {{$t('ADD_CASE.EXPECTED_CLOSE_DATE')}}
                        p.f-text-highlight.mb-0 {{ caseDetail.due_date }}
                    //- v-col.pa-2.pa-md-3(cols='6' md='3' v-if='!isEdit')
                    //-     v-card-subtitle.pa-0.t-lightgary 進線電話
                    //-     a.t-primary.mb-0.f-text-highlight.ellipsis(:href='onCall(caseDetail.incoming_phone)') {{caseDetail.incoming_phone}}
                    //- v-col.pa-2.pa-md-3(cols='6' md='3' v-if='!isEdit')
                    //-     v-card-subtitle.pa-0.t-lightgary 進線電郵
                    //-     a.t-primary.mb-0.f-text-highlight.ellipsis(:href='onMail(caseDetail.incoming_email)') {{caseDetail.incoming_email}}
                    v-col.pa-2.pa-md-3(cols='6' md='2' v-if='!isEdit')
                        v-card-subtitle.pa-0.t-lightgary {{$t('TO_CASE.PRIORITY')}}
                        v-row(no-gutters='no-gutters')                            
                                v-icon(v-if="caseDetail.priority == 'VERY_HIGH'" :title="getItemNameFromList(casePriorityList, caseDetail.priority)" size='20' color='red') fas fa-angle-double-up
                                v-icon(v-if="caseDetail.priority == 'HIGH'" :title="getItemNameFromList(casePriorityList, caseDetail.priority)" size='20' color='red') fas fa-angle-up
                                v-icon(v-if="caseDetail.priority == 'MEDIUM'" :title="getItemNameFromList(casePriorityList, caseDetail.priority)" size='20' color='orange') fas fa-equals
                                v-icon(v-if="caseDetail.priority == 'LOW'" :title="getItemNameFromList(casePriorityList, caseDetail.priority)" size='20' color='primary') fas fa-angle-down
                                v-icon(v-if="caseDetail.priority == 'VERY_LOW'" :title="getItemNameFromList(casePriorityList, caseDetail.priority)" size='20' color='primary') fas fa-angle-double-down
                                p.f-text-highlight.mb-0.ml-2 {{getItemNameFromList(casePriorityList, caseDetail.priority_number)}}
                    v-col.pa-2.pa-md-3(cols='6' md='2' v-if='!isEdit')
                        v-card-subtitle.pa-0.t-lightgary {{$t('TO_CASE.STATUS')}}
                        p.f-text-highlight.mb-0 {{ getItemNameFromList(caseStatusList, caseDetail.status) }}
                    //-Edit-----                    	
                    //-contact edit
                    v-col.pa-2.pa-md-3.ellipsis(cols='6' md='3' v-if='isEdit')
                        div.d-flex.align-center.justify-space-between
                        .d-inline-flex.align-center.w-100
                            v-text-field(v-if='isEdit && isNew.primary_contact' v-model="newPri.lName" :label= `$t('ADD_CONTACT.LAST_NAME')` validate-on-blur required="required" large="large" @input='cleanFirstName(newPri)' :rules="formRule.contactNameRules")
                            v-text-field(v-if='isEdit && isNew.primary_contact ' v-model="newPri.fName" :label= `$t('ADD_CONTACT.FIRST_NAME')` large="large" :rules="formRule.contentLenRule50")
                            v-btn.pr-0(class="ma-0" outlined fab small color="white" @click='isNew.primary_contact = false' v-if='isEdit && isNew.primary_contact')
                                v-icon(size='20' color="black") mdi-window-close
                            v-combobox#oppty_contact(v-if='isEdit && !isNew.primary_contact' v-model="caseDetail.primary_contact" :label=`$t('TO_CASE.NAME')`  :items="contactData" item-text="searchStr" item-value="id", :no-data-text=`$t('ADD_CASE.NO_CONTACT')` :search-input.sync="search_case_contact" :loading="isLoading.primary_contact" :rules='formRule.contactRules'  )
                                template(v-slot:prepend-item)
                                    v-list-item
                                        v-btn(width='120' height='32' color='blue' plain border-0 @click='isNew.primary_contact = true')
                                            v-icon.mr-1(size='14') icon-add
                                            span {{$t('ADD_DEAL.NEW_CONTACT')}}
                                template(v-slot:item='data')
                                    v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                        v-icon(v-if="data.item.image == null") mdi-account
                                        img(v-else :src='data.item.image')
                                    .t-black {{ data.item.full_name }}
                                    .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                template(v-slot:selection='data')
                                    .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                    //-expected_close_date edit
                    v-col.pa-2.pa-md-3.ellipsis(cols='6' md='3' v-if='isEdit')                       
                        v-menu(v-if='isEdit' ref="menu" v-model="menu"  :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                            template(v-slot:activator="{ on, attrs }")
                                v-text-field(v-model='caseDetail.due_date' v-bind="attrs" v-on="on" :label=`$t('TO_CASE.EXPECTED_CLOSE_DATE')` )                                
                            v-date-picker(v-model='caseDetail.due_date' @input='menu = false' no-title scrollable)     
                    v-col.pa-2.pa-md-3(cols='6' md='2' v-if='isEdit')                        
                        v-select(v-model='caseDetail.priority_number' :label=`$t('TO_CASE.PRIORITY')` :items='casePriorityList' item-text='name' item-value='key' v-show='isEdit' )
                    v-col.pa-2.pa-md-3(cols='6' md='2' v-if='isEdit')                        
                        v-select(v-model='caseDetail.status' :label=`$t('TO_CASE.STATUS')` :items='caseStatusList' item-text='name' item-value='key' v-show='isEdit' )

                    //-Edit END
                    v-col.pa-2.pa-md-3(cols='12' md='2')
                        v-list-item.pa-0
                            v-list-item-avatar.ma-0.mr-2(color='aliceBlue')
                                span.mx-auto(v-if="!isPhoto") {{ caseDetail.owner.last_name }}
                                img(v-else :src='caseDetail.owner.usersetting.photo' :alt='caseDetail.owner.last_name')
                            v-list-item-content.pa-0
                                v-card-subtitle.pa-0.t-lightgary {{$t(('TO_CASE.OWNER'))}}
                                a.t-primary.mb-0.f-text-highlight.ellipsis(:href='onMail(caseDetail.owner.email)') {{ caseDetail.owner.last_name+ " "+caseDetail.owner.first_name }}
        v-skeleton-loader(v-if='permitted' v-show='!isCreated' type="article, actions")
        v-card-text.process-card.pt-0.mt-md-10(v-if='permitted' v-show='isCreated' )            
            v-stepper.process-box(v-model='currentStep' )
                v-stepper-header.process-bar
                    template(v-for='item in caseSummary')
                        v-stepper-step(v-if='!isEdit' :key='`${item.sequence_number}-process`' :complete='currentStep > item.sequence_number || isProcessWon' :step='item.sequence_number' :class='customStagecolor'  :style='getcolor(item.stage_color)'  edit-icon='icon-check' :editable='isEdit')
                            span(v-if='currentStep < item.sequence_number')  {{ item.stage_name }}
                            span(v-else)  {{ item.stage_name }} {{ (item.stage_duration/(24*60*60)).toFixed(1) }} {{$t('CASE_DETAIL.DAY')}}
                        v-stepper-step(v-if='isEdit' @click='onChangeCaseStage(item.id)' :key='`${item.sequence_number}-process`' :complete='currentStep > item.sequence_number || isProcessWon' :step='item.sequence_number' :class='customStagecolor'  :style='getcolor(item.stage_color)'  edit-icon='icon-check' :editable='isProcessEdit')
                            span(v-if='currentStep < item.sequence_number')  {{ item.stage_name }}
                            span(v-else)  {{ item.stage_name }} {{ (item.stage_duration/(24*60*60)).toFixed(1) }} {{$t('CASE_DETAIL.DAY')}}
                    v-btn.mx-1(v-if='allStage > 6 && isFirst && isProcessEdit' absolute style="marginTop: 4px; zIndex: 50;" fab dark x-small color="white" @click='preStage()')
                        v-icon(color="primary") icon-arrow-left
                    v-btn(v-if='allStage > 6 && isLast && isProcessEdit' absolute style="marginTop: 4px; zIndex: 50; right: 5px"  fab dark x-small color="white" @click='nextStage()')
                        v-icon(color="primary") icon-arrow-right

                v-row(no-gutters)
                    v-col(cols='12' md='11')
                        v-stepper-items(:class="stepContent ? '':'hide' ")
                            v-stepper-content.pa-0(v-for='(item, index) in allProcess' :key='`${item.sequence_number}-process`' :step='item.sequence_number')
                                v-card(color flat)
                                    v-row(no-gutters='no-gutters')
                                        v-col.pr-md-10(cols='12' md='12')
                                            h4.my-2 {{$t(('TO_CASE.GUIDE'))}}
                                            p.d-flex.align-center.input-has-label.dance
                                                v-textarea.textarea-style(v-model='item.guideline' :id='`${index}-guideline`' solo readonly no-resize dense='dense' :hide-details='true' height='100px')
                    v-col.pt-4(cols='12' md='1')
                        a( :href="'/Case/' + caseData.id")
                            v-btn.go-deal-btn(@click='toCasePage()' width='100%' height='100%' color='purple' outlined)
                                v-icon(size='36') icon-chatmoney-border
                                span {{$t(('TO_CASE.DETAIL_PAGE'))}}
</template>

<script>
    import Vue from 'vue';
    import CaseDataService from "@/services/CaseDataService";
    import casePriorityList from "@/array/the_casePriorityList";
    import caseStatusList from "@/array/the_caseStatusList";
    import ContactDataService from '@/services/ContactDataService';
    import closeCaseReasonList from "@/array/the_closeCaseReasonList";
    import i18n from '/common/plugins/vue-i18n.js'

    export default Vue.extend({
        props: {
            caseData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                caseDetail:{owner:{}},
                closeReasonName: null,
                isProcessWon: false,
                isProcessLost: false,
                isProcessComplete: false,
                // process bar
                allStage: 0,
                currentStep: 0,
                caseSummary: {},
                isFirst: true,
                isLast: true,
                stepContent: true,
                allProcess: [],
                customStagecolor:'customcolor',
                casePriorityList: casePriorityList,
                caseStatusList:caseStatusList,
                closeCaseReasonList: closeCaseReasonList,
                addDealValid: true,
                contactId:"",
                contactName:"",
                permitted: true,
                isProcessEdit: true,                			
                isCreated: false,
                loading: false,
                isPhoto: false,                
                isEdit:false,
                formRule: {
                    nameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_Case'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    dateRule: [(v) => !!v || i18n.t('RULE.RULE_R_Case'),],
                    contactRules: [(v) => !!v || i18n.t('RULE.RULE_R_Case_Contact')],
                    contactNameRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                    ],
                    contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                },
                menu: false,
                originCaseDetails: {},
                isNew: {
                    primary_contact: false,
                },
                isLoading: {
                    primary_contact: false,
                },
                comboInput:{
                    primary_contact: null,
                },
                contactData: [],
                search_case_contact: null,                
                newPri:{
                    fName: null,
                    lName: null,
                },
                open:true,
                hadEdit:false,
                caseNameWidth:"",
                loading_caseDeatil: false,
                }
        },       
        async created(){            
            this.isCreated = true
            await (this.isCreated = false);
            await CaseDataService.detail(this.caseData.id).then((response)=>{
                this.caseDetail = response.data;
                this.caseDetail.priority_number = this.getPriorityNumber(this.caseDetail.priority);
                this.allProcess = response.data.summary;
                this.allStage = this.allProcess.length;
                this.contactId = this.caseDetail.primary_contact.id
                this.contactName = this.caseDetail.primary_contact.full_name
                this.closeReasonName = this.getItemNameFromList(this.closeCaseReasonList, this.caseDetail.closed_reason);
            })
            if (this.caseDetail.owner.usersetting.photo != null) {
                this.isPhoto = true;
            }
            await (this.isCreated = true);
            await (this.currentStep = this.allProcess.find(d=> d.id == this.caseDetail.current_stage).sequence_number);
            await this.setcaseSummary();
            this.currentStep = this.currentStep - 1;  

            if (this.caseDetail.closed_reason == "RESOLVED") {
                this.stepContent = true;
                this.isProcessEdit = false;
                this.isProcessComplete = true;
                this.isProcessWon = true;					
                this.customStagecolor = 'woncolor'
                this.open = false
            } else if (this.caseDetail.closed_reason != null) {
                this.stepContent = false;
                this.isProcessEdit = false;
                this.isProcessComplete = true;
                this.isProcessLost = true;
                this.stepContent= true;
                this.open = false
            }     
            this.caseStatusList = this.caseStatusList.filter(el=>el.key!="CLOSED")
            this.getNameWidth();
        },
        methods: {
            getNameWidth(){
                if(this.$refs.nameWidth.clientWidth > 704){
                this.caseNameWidth = '11'
                }else
                {
                    this.caseNameWidth = 'auto'
                }
                if(this.$refs.nameWidth.clientWidth == 704 && this.caseDetail.subject.length>24)
                {
                    this.caseNameWidth = '11'
                } 
            },
            validateForm(){
            return this.$refs.form.validate();
        },
            setcaseSummary() {
            if (this.allStage > 6) {
                if (this.currentStep > this.allStage - 4) {
                    this.isLast = false;
                    this.caseSummary = this.allProcess.slice(this.allStage - 6, this.allStage + 6);
                }
                else if (this.currentStep < 4) {
                    this.isFirst = false;
                    this.caseSummary = this.allProcess.slice(0, 6);
                }
                else {
                    this.isFirst = true;
                    this.isLast = true;
                    this.caseSummary = this.allProcess.slice(this.currentStep - 3, this.currentStep + 3);
                }
            }
            else {
                this.caseSummary = this.allProcess;
            }
            this.currentStep = this.currentStep + 1;
            },
            nextStage() {                
                this.currentStep = this.currentStep + 1;
                if (this.caseSummary[5].sequence_number > this.allStage - 6) {
                    let n = this.allStage - 5;
                    this.changeAddStage(n);
                } else if (this.caseSummary[5].sequence_number < this.allStage) {
                    let n = this.caseSummary[5].sequence_number + 1;
                    this.changeAddStage(n);
                }
                if (this.caseSummary[0].sequence_number == 1) {
                    this.isFirst = false;
                } else {
                    this.isFirst = true;
                }
                if (this.caseSummary[5].sequence_number == this.allProcess.length) {
                    this.isLast = false;
                } else {
                    this.isLast = true;
                }
            },
            async preStage() {
                if (this.caseSummary[0].sequence_number <= 6) {
                    let n = 6;
                    await this.changeMinusStage(n);
                } else {
                    let n = this.caseSummary[0].sequence_number - 1;
                    await this.changeMinusStage(n);
                }
                await (this.currentStep = this.currentStep + 1);
                if (this.caseSummary[0].sequence_number == 1) {
                    this.isFirst = false;
                } else {
                    this.isFirst = true;
                }
                if (this.caseSummary[5].sequence_number == this.allProcess.length) {
                    this.isLast = false;
                } else {
                    this.isLast = true;
                }
            },
            async changeAddStage(n) {
                await (this.caseSummary = this.allProcess.slice(n - 1, n + 5));
                await (this.currentStep = this.currentStep - 1);
            },
            changeMinusStage(n) {
                this.currentStep = this.currentStep - 1;
                this.caseSummary = this.allProcess.slice(n - 6, n);
            },
            onChangeCaseStage(data) {
                this.stageData = { "current_stage_id": data }

                if(this.caseDetail.current_stage != data && this.isProcessEdit == true)
                {
                    CaseDataService.updatestage(this.caseDetail.id, this.stageData)
                    .then(response =>{
                        this.succMode="STAGE";
                        this.stageNumber = this.allProcess.find(d => d.id == response.data.current_stage_id).sequence_number;
                        this.stageName = this.allProcess.find(d => d.id == response.data.current_stage_id).stage_name;                        
                        this.caseDetail.current_stage = data;
                        this.hadEdit = true;
                    });
                }
            },
            onCall: function (cell) {
                return "tel:" + cell;
                },
            onMail: function (mail) {
                return "mailto:" + mail;
            },

            getCloseReasonName(){
                if(this.caseDetail.closed_reason != null && this.caseDetail.closed_reason != false) {
                    const closeReasonObj = this.closeReasonList.find(e => e.key == this.caseDetail.closed_reason);
                    return closeReasonObj.name;
                }
                else{
                    return '';
                }
            },
            getcolor: function(data) {
                this.colorQ= data
                return {
                    '--backgroundColor':this.colorQ,
                    '--color': this.colorQ		
                }
            },
            validate() {
                this.$refs.form.validate()
            },
            onCancel() {
                const routeNAme = this.$route.name;
                if(this.hadEdit)
                {
                    if(routeNAme==i18n.t('Bower_Title.Contact')|routeNAme==i18n.t('Bower_Title.Account')|routeNAme==i18n.t('Bower_Title.Deal'))
                    {
                        this.$emit('emitToCaseDialog',this.caseDetail);
                    }else
                    {
                        this.$emit('emitToCaseDialog',true); 
                    }                    
                }
                else
                {
                    this.$emit('emitToCaseDialog',false);}
            },
            toCasePage: function () {
                this.$router.push(`/Case/${this.caseData.id}`);
            },
            onEdit(){
                this.isEdit = true
                this.originCaseDetails = JSON.parse(JSON.stringify(this.caseDetail));
            },
            async confirmEdit(){
                if (this.comboInput.primary_contact != '' && this.$refs.form.validate()) {	
                    await (this.loading_caseDeatil = true);
                    this.validateForm();
                    let data = JSON.parse(JSON.stringify(this.caseDetail));
                    
                    if (data.case_org == null)
                        data.case_org = {};

                    if(this.isNew.primary_contact && this.newPri.lName)
                    {
                        data.primary_contact= {
                            "id": 0,
                            "last_name": this.newPri.lName,
                            "first_name": null,
                        };
                        data.primary_contact.last_name = this.newPri.lName;
                        if(this.newPri.fName != null)
                            data.primary_contact.first_name = this.newPri.fName;
                        else 	
                            data.primary_contact.first_name = null;
                    } else if (this.isNew.primary_contact && !this.newPri.lName) {
                        data.primary_contact = {};
                    } 

                    delete data.stage_progress;
                    delete data.stage_score;
                    delete data.summary;
                    delete data.owner;
                    delete data.stages;
                    delete data.contacts;
                    delete data.knowledge_articles;

                    await CaseDataService.editdetail(data)
                    .then(response => {
                        this.caseDetail = response.data;
                        this.caseDetail.priority_number = this.getPriorityNumber(this.caseDetail.priority);
                        this.contactId = this.caseDetail.primary_contact.id;
                        this.contactName = this.caseDetail.primary_contact.full_name;
                        this.isDetailReadonly = true;
                        this.hadEdit = true;
                        this.defaultDetail(); 
                        this.isEdit = false;
                    })
                    await(this.getNameWidth())
                    await (this.loading_caseDeatil = false);
                }
            },
            defaultDetail(){
                this.isNew = {
                    primary_contact: false,
                    case_org: false,
                }
                this.newPri = {
                    fName: null,
                    lName: null,
                }
            },
            cancelEdit(){
                this.isEdit = false
                this.caseDetail = this.originCaseDetails;                
                this.defaultDetail();               
            },         
                
            cleanFirstName(field){
            if(field.lName == null || field.lName == "")
                field.fName = null;
            },     
            onClickOutside(){
                if(!this.isEdit)
                {                
                const routeNAme = this.$route.name;
                if(this.hadEdit)
                {
                    if(routeNAme==i18n.t('Bower_Title.Contact')|routeNAme==i18n.t('Bower_Title.Account')|routeNAme==i18n.t('Bower_Title.Deal'))
                    {
                        this.$emit('emitToCaseDialog',this.caseDetail);
                    }else
                    {
                        this.$emit('emitToCaseDialog',true); 
                    }
                    
                }
                else
                {
                    this.$emit('emitToCaseDialog',false);}}
            } 
        },
        watch: {
            async search_case_contact(val){
                 // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;
                let array = [];

                // search
                if(isEnZhNum && !!val && isString){
                    this.isLoading.primary_contact = true;
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            item.searchStr = item.full_name 
                                            +item.full_name_en
                                            +item.full_name_en.toLowerCase()
                                            +item.full_name_en.toUpperCase()
                                            +item.full_name_zh;
                            if(item.searchStr.includes(val)){
                                if(item.organization_set.length > 0)
                                {
                                    item.org_name = "[ "+item.organization_set[0].name +" ]";
                                }
                                array.push(item);
                            }
                        })
                        this.contactData = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.isLoading.primary_contact = false;
                    })
                }
                else{
                    this.contactData = [];
                }
            
            },
        }
    });
</script>

<style scoped>
.opptyNameField{
      margin-top: 18px;
}
.opptyNameFieldInput {
    font-size: 1.6em;
}
.caseNameField{
      margin-top: -25px;
}
.casePhoneField{
      margin-top: 16px;
}
</style>