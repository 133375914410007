<template lang="pug">
v-card(:class='contactList.length >= 8 ? "dialog-height-big" : ""')
  v-col.d-flex.align-center.pa-5.pb-0
    v-row(no-gutters)
      v-col(cols="7")
        .d-flex.align-center
          h2.my-0 {{$t('ADD_CASE_PRODUCT.TITLE')}}         
      v-col.text-right(cols="4")
        .btn-group.ml-auto.mr-2
          v-text-field(
            v-model="searchProduct",
            flat,
            dense,
            solo,
            prepend-inner-icon="icon-search",
            hide-details
          )
      v-col.text-right(cols="1")
        v-btn.ml-auto(
          :ripple="false",
          @click="onCancel",
          depressed="depressed",
          icon
        )
          v-icon icon-cancel
  v-card-text.pt-4.px-5.pb-5
    .data-area
      v-data-table.border-table(
        :headers="mainContactHeader",
        :items="productList",
        :items-per-page='itemPerPage' 
        :page-count='pageCount'   
        :page.sync='page'
        :sort-desc.sync='sortByDesc'
        :sort-by.sync="sortBy"
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}"
        multi-sort
        hide-default-footer="hide-default-footer"
        show-select item-key='id' v-model="selectedRows" :loading='isLoading' loading-text="Loading..." 
      )        
        template(v-slot:item.name='{ item }')
            .ellipsis.t-black(:title='item.name') {{ item.name}}
        template(v-slot:item.category='{ item }')
            .ellipsis.t-black(:title='item.category') {{ item.category}}
        template(v-slot:item.series='{ item }')
            .ellipsis.t-black(:title='item.series') {{ item.series}}
        template(v-slot:item.product_code='{ item }')
            .ellipsis.t-black(:title='item.product_code') {{ item.product_code}}
      v-row(no-gutters).my-5
        v-col.d-flex.justify-end(cols="12")
            v-btn(:ripple='false' @click='onConfirm' width='150' height='40' color='success' depressed dark) {{$t('GENERAL.ADD')}}  
      v-pagination.mt-3(
        v-model='page' :length='pageCount' circle :total-visible="10" v-if='!isLoading'
      )  
  v-dialog(
    v-model="successDialog",
    width="360",
    content-class="statusDialog"
  )
    success-dialog(@emitSuccessDialog="onEmitSuccessDialog")
  v-dialog(
    v-model="messageDialog",
    width="360",
    content-class="statusDialog"
  )
    message-dialog(
      @emitMessageDialog="onEmitMessageDialog",
      :message="message"
    )
</template>

<script>
import Vue from "vue";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import ProductDataService from "@/services/ProductDataService";
import CaseDataService from "@/services/CaseDataService";
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  props: {
    caseProductList: {
      type: Array,
      required: true,
    },
    caseId: {
      type: String,
      required: true,
    },
  },
  components: { 
    successDialog,
    messageDialog,
  },  
  data() {
    return {
      productList:[],
      isLoading:true,
      selectedRows:[],
      contactList:[],
      searchProduct: "",
      // mainContactPage: 1,
      // mainContactPageCount: 0,
      // mainContactItemPerPage: 8,
      awaitingSearch: false,   
      sortBy:[],
      sortByForSearch:[],
      sortByDesc:[],
      pageDataCount:null,
      page: 1,
      pageCount: 1,
      itemPerPage:8,
      mainContactHeader: 
            [
                {  text: i18n.t('PRODUCTSETTING.NAME'), value: 'name', align: 'left', width: '25%' },
                {  text: i18n.t('PRODUCTSETTING.CATEGORY'), value: 'category.name', align: 'left', width: '20%'},
                {  text: i18n.t('PRODUCTSETTING.SERIES'), value: 'series.name', align: 'center' , width: '20%'},
                {  text: i18n.t('PRODUCTSETTING.PRODUCTCODE'), value: 'product_code', align: 'center' , width: '25%'},
                // {  text: i18n.t('PRODUCTSETTING.PRICR'), value: 'unit_price', align: 'center' , width: '25%'},
                // {  text: i18n.t('PRODUCTSETTING.COST'), value: 'unit_cost', align: 'center' , width: '25%'},
                // {  text: i18n.t('PRODUCTSETTING.DESCRIPTIONL'), value: 'description', align: 'center' , width: '25%'},
                // {  text: i18n.t('MANAGEUSERS.ACTIVE'), value: 'active', align: 'center', width: '15%'  , sortable: false,},
            ],
      successDialog: false,
      messageDialog: false,
      message: "",
      addContact:[],
      // Dialog
      addMainContactDialog: false,
      editMainContactDialog: false,
      addMainContactData:{},
      edtorMainContactData:{},
    };
  },
  async created() {
    this.getListData()
  },
  methods: {        
    async getListData(){
      this.isLoading = true;
      await ProductDataService.list(this.page,this.searchProduct,this.itemPerPage,this.sortByForSearch)
            .then(response => 
            {  
                this.pageCount = response.data.total_page
                this.pageDataCount = response.data.pageDataCount
                this.productList = response.data.list;
                this.productList.forEach(data=>
                {
                  if(this.caseProductList.includes(data.id))
                  {
                    this.selectedRows.push(data)
                  }
                }
                )
                this.isLoading = false
            });
    },
    onResize: function () {
      let windowH = window.innerHeight - 150; //table高度
      let objH = 70;
      this.itemPerPage = parseInt(windowH/objH);
    },
    validate() {
      this.$refs.form.validate();
    },    
    // Common Function
    onCall: function (cell) {
      return "tel:" + cell;
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    }, 
    onEmitMessageDialog() {
      this.messageDialog = false;      
    },   
    async onConfirm() {

      let data = {case:this.caseId,product:[]}
      let addList = []
      for(let i in this.selectedRows)
      {
        if(!this.caseProductList.includes(this.selectedRows[i].id)){
          data.product.push(this.selectedRows[i].id)
          addList.push(this.selectedRows[i])
        }
        // data.product.push(this.selectedRows[i].id)
      }

      CaseDataService.addcaseproduct(data).then(()=>{
        this.$emit("emitAddCaseProductDialog",addList);
      })
    },
    onCancel() {      
      this.$emit("emitAddCaseProductDialog", false);
    },
  },
  watch:{
    "page":async function () {      
            await this.getListData()  
        },
    "searchProduct":async function () {
    if (!this.awaitingSearch) {
        setTimeout(() => {
            this.page = 1
            this.getListData();
            this.awaitingSearch = false;
        }, 1000);
        }
        this.awaitingSearch = true;
    },   
    "sortByDesc":async function () {   
    this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
    for(let i in this.sortByDesc)
    {
        if(this.sortByDesc[i] == true)
        {
        this.sortByForSearch[i] = '-'+this.sortByForSearch[i]
        }        
    }
    this.page = 1;
    await this.getListData();
    },
  }
});
</script>